function toggleSideBar() {
    document.querySelector('.js-closeSmallScreenMenu').classList.toggle('hidden');
    document.querySelector('.js-openSmallScreenMenu').classList.toggle('hidden');
    document.querySelector('.js-uiKit__sidebar').classList.toggle('uiKit__sidebar--expanded');
}

function initPage() {
    const designMode = document.getElementById('designModeToggle');
    if (designMode) {
        designMode.addEventListener('change', toggleContentEditable);
    }
    document.querySelector('.js-toggleSidebar').addEventListener('click', toggleSideBar);
}

function toggleContentEditable() {
    const elements = document.querySelectorAll('.componentExample');
    elements.forEach(function(element) {
        const isEditable = element.getAttribute('contenteditable');
        element.setAttribute('contenteditable', isEditable === 'true' ? 'false' : 'true');
    });
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initPage) : initPage();
